import React, {useState} from 'react'
import Header from './Header'
import Footer from './Footer'
import FreeEstimate from './FreeEstimate';
import '../styles/main.scss';

export default function Layout({hideNav, children}) {
    const [isOpen, setOpen] = useState(false);
    const hideMobileMenu = () => {
        setOpen(false)
    }

    return (
        <div className={`layout ${isOpen ? "isOpen" : ""} ${hideNav ? "page-404" : ""}`}>
            <Header isOpen={isOpen} setOpen={setOpen} hideMobileMenu={hideMobileMenu}/>

            <main className="main-content">
                {children}
            </main>
            <FreeEstimate />
            <Footer />
        </div>
    )
}
