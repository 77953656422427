import { Link } from 'gatsby'
import React from "react"
import Container from 'react-bootstrap/Container'
import { Divide as Hamburger } from 'hamburger-react'
import logo from "../../static/header/logo_tidyllc_trans@2x.png"


export default function Header({isOpen, setOpen, hideMobileMenu}) {

    return (
        <header>
            <Container>
                <nav>
                    <Link to="/" className="header-logo">
                        <img src={logo} alt="Tidy LLC" />
                    </Link>

                    <div className="mobile-toggle">
                        <Hamburger color="#4A8483" toggled={isOpen} toggle={setOpen} />
                    </div>
                    
                    <div className={`links ${isOpen ? "isOpen" : ""}`} onClick={hideMobileMenu} onKeyDown={hideMobileMenu} role="menubar" tabIndex={0}>
                        <a href="/#services" className="link" role="menuitem" >Services</a>
                        <a href="/#about-us" className="link" role="menuitem" >About Us</a>
                        <a href="/#contact-us" className="link" role="menuitem">Contact Us</a>
                    </div>
                </nav>
            </Container>
        </header>
    )
}
