import React from 'react'
import Container from 'react-bootstrap/Container'

export default function Footer() {
    const year = new Date().getFullYear();
    
    return (
        <footer>
            <Container>
                <address>
                    <span>TIDY, LLC</span>
                    <span>PO Box 4407, Naperville, IL 60567</span>
                    <span><a href="mailto:Services@TidyLLC.com">Services@TidyLLC.com</a></span>
                </address>
                <p>&copy; {year} TidyLLC</p>
            </Container>
        </footer>
    )
}
