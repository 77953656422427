import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import InputMask from 'react-input-mask'
import { useFormik } from 'formik'
import STATE_LIST from '../data/STATE_LIST'

export default function FreeEstimate() {
    let states = []
    for(let state in STATE_LIST) {
        states.push(state);
    }

    const validate = values => {
        const errors = {};

        if(!values.name) {
            errors.name = 'Required'
        } else if(values.name.length < 2) {
            errors.name = "Must be at 3 characters or more"
        }

        if(!values.email) {
            errors.email = 'Required'
        } else if(values.email.length < 4) {
            errors.email = "Must be at 5 characters or more"
        }

        if(!values.city) {
            errors.city = 'Required'
        } else if(values.city.length < 2) {
            errors.city = "Must be at 3 characters or more"
        }

        if(!values.state) {
            errors.state = 'Required'
        } else if(values.state.length < 2) {
            errors.state = "Must be at 2 characters"
        }

        if(!values.zip) {
            errors.zip = 'Required'
        } else if(values.zip.length < 5) {
            errors.zip = "Must be 5 characters"
        }

        return errors;
    }
    
    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            city: '',
            state: '',
            zip: '',
            phone: '',
            companyName: '',
            message: ''
        },
        validate,
        onSubmit: (values, {resetForm}) => {
            resetForm(this.initialValues)
        }
    })
    return (
        <section id="contact-us">
            <div className="container">
                <h2 className="section-heading white">Receive a Free Estimate from Tidy LLC</h2>
            </div>

            <div className="free-estimate__wrap">
            <form method="POST" action="https://formsubmit.co/Services@TidyLLC.com" className="form">
            <div className="row">
                <div className="col-lg-6">
                    <div className="form-group">
                        <label htmlFor="name" className="form-label">Name*</label>
                        <input type="text" id="name" name="name" className="form-control" onChange={formik.handleChange} value={formik.values.name} onBlur={formik.handleBlur} />
                        {formik.errors.name ? <div className="error">{formik.errors.name}</div> : null}
                    </div>
                    <div className="form-group">
                        <label htmlFor="email" className="form-label">Email Address*</label>
                        <input type="email" id="email" name="email" className="form-control" onChange={formik.handleChange} value={formik.values.email} onBlur={formik.handleBlur} />
                        {formik.errors.email ? <div className="error">{formik.errors.email}</div> : null}
                    </div>
                    <div className="row location-details">
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor="city" className="form-label">City*</label>
                                <input type="text" id="city" name="city" className="form-control" onChange={formik.handleChange} value={formik.values.city} onBlur={formik.handleBlur} />
                                {formik.errors.city ? <div className="error">{formik.errors.city}</div> : null}
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label htmlFor="state" className="form-label">State*</label><br/>
                                <select id="state" name="state" className="form-control" onChange={formik.handleChange} value={formik.values.state} onBlur={formik.handleBlur}>
                                <option>- Select -</option>
                                {states.map((state, index) => (
                                    <option  key={index} value={state}>{state}</option>
                                ))}
                                </select>
                                {formik.errors.state ? <div className="error">{formik.errors.state}</div> : null}
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label htmlFor="zip" className="form-label">Zip</label>
                                <input 
                                    type="text" 
                                    id="zip" 
                                    name="zip" 
                                    pattern="[0-9]*" 
                                    maxLength="5"
                                    className="form-control"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange} value={formik.values.zip}/>
                                    {formik.errors.zip ? <div className="error">{formik.errors.zip}</div> : null}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6">
                    <div className="form-group">
                        <label htmlFor="phone" className="form-label">Phone</label>
                        <InputMask 
                            type="tel" 
                            id="phone" 
                            name="phone" 
                            className="tel-input form-control" 
                            mask={'(999) 999-9999'}
                            onChange={formik.handleChange} value={formik.values.phone} />
                    </div>

                    <div className="form-group">
                        <label htmlFor="companyName" className="form-label">Company Name</label>
                        <input type="text" id="companyName" name="companyName" className="form-control"  onChange={formik.handleChange} value={formik.values.companyName} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="message form-group">
                        <label htmlFor="message" className="form-label">Please tell us about your needs. The more detail you can give, the more accurately we can estimate your project.</label><br/>
                        <textarea name="message" className="form-control" id="message" rows="10" onChange={formik.handleChange} value={formik.values.message}></textarea>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <button className="btn btn-submit" type="submit">Submit</button>  
                </div>
            </div>
        </form>
            </div>
            
        </section>
        
    )
}

// <section id="contact-us">
//             <Container>
//                 <h2 className="section-heading white">Receive a Free Estimate from Tidy LLC</h2>

//                 <div className="free-estimate__wrap">
//                     <Form onSubmit={formik.handleSubmit} className="form">
//                         <Row>
//                             <Col lg={6}>
//                                 <Form.Group>
//                                     <Form.Label htmlFor="name">Name *</Form.Label>
//                                     <Form.Control 
//                                         type="text" 
//                                         id="name" 
//                                         name="name" 
//                                         onChange={formik.handleChange} 
//                                         value={formik.values.name}
//                                         required />
//                                 </Form.Group>
//                                 <Form.Group>
//                                     <Form.Label htmlFor="email">Email Address *</Form.Label>
//                                     <Form.Control 
//                                         type="email" 
//                                         id="email" 
//                                         name="email" 
//                                         onChange={formik.handleChange} 
//                                         value={formik.values.email}
//                                         required />
//                                 </Form.Group>
//                                 <Row className="location-details">
//                                     <Col lg={6}>
//                                         <Form.Group>
//                                             <Form.Label htmlFor="city">City *</Form.Label>
//                                             <Form.Control 
//                                                 type="text" 
//                                                 id="city" 
//                                                 name="city" 
//                                                 onChange={formik.handleChange} 
//                                                 value={formik.values.city}
//                                                 required />
//                                         </Form.Group>
//                                     </Col>
//                                     <Col lg={3}>
//                                         <Form.Group>
//                                             <Form.Label htmlFor="state">State *</Form.Label>
//                                             <Form.Control 
//                                                 as="select" 
//                                                 id="state" 
//                                                 name="state"
//                                                 onChange={formik.handleChange} 
//                                                 value={formik.values.state}
//                                                 required>
//                                                 <option>- Select -</option>
//                                                 {states.map((state, index) => (
//                                                     <option  key={index} value={state}>{state}</option>
//                                                 ))}
//                                             </Form.Control>
//                                         </Form.Group>
//                                     </Col>
//                                     <Col lg={3}>
//                                         <Form.Group>
//                                             <Form.Label htmlFor="zip">Zip *</Form.Label>
//                                             <Form.Control 
//                                                 type="text" 
//                                                 id="zip" 
//                                                 name="zip" 
//                                                 pattern="[0-9]*" 
//                                                 maxLength="5" 
//                                                 onChange={formik.handleChange} 
//                                                 value={formik.values.zip}
//                                                 required />
//                                         </Form.Group>
//                                     </Col>
//                                 </Row>
//                             </Col>
//                             <Col lg={6}>
//                                 <Form.Group>
//                                     <Form.Label htmlFor="phone">Phone</Form.Label>
//                                     <InputMask 
//                                         type="tel" 
//                                         id="phone" 
//                                         name="phone" 
//                                         className="tel-input form-control" 
//                                         mask={'(999) 999-9999'} 
//                                         onChange={formik.handleChange} 
//                                         value={formik.values.phone}/>
//                                 </Form.Group>
//                                 <Form.Group>
//                                     <Form.Label htmlFor="companyName">Company Name</Form.Label>
//                                     <Form.Control 
//                                         type="text" 
//                                         id="companyName" 
//                                         name="companyName"
//                                         onChange={formik.handleChange} 
//                                         value={formik.values.companyName}/>
//                                 </Form.Group>
//                             </Col>
//                         </Row>
//                         <Row>
//                             <Col lg={12}>
//                                 <Form.Group className="message">
//                                     <Form.Label htmlFor="message">Please Tell us about your needs. The more detail you can give, the more accurately we can estimate your project.</Form.Label>
//                                     <Form.Control
//                                         id="message"
//                                         as="textarea"
//                                         style={{ height: '20rem' }}
//                                         onChange={formik.handleChange} 
//                                         value={formik.values.message}
//                                     />
//                                 </Form.Group>
//                             </Col>
//                         </Row>
//                         <Row>
//                             <Col lg={12}>
//                                 <Button className="btn btn-submit">Submit</Button>                
//                             </Col>
//                         </Row>
//                     </Form>
//                 </div>
//             </Container>
//         </section>